<template>
  <b-row>
    <b-col sm="12" md="12" class="mt-1">
      <b-form @submit.prevent>
        <b-card>
          <b-card-body>
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
              <b-form
                @submit.prevent="handleSubmit(onSubmit)"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="departing"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.departing')
                      "
                      :label="$t('fields.departing')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.departing')
                      "
                      :valuex="appData.departing"
                      @updatedata="(val) => (appData.departing = val)"
                      validations="required"
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="destination"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.destination')
                      "
                      :label="$t('fields.destination')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.destination')
                      "
                      :valuex="appData.destination"
                      @updatedata="(val) => (appData.destination = val)"
                      validations="required"
                    ></inputtext>
                  </b-col>

                  <b-col sm="12" md="6" class="mt-1">
                    <label> {{ $t("fields.travelingdate") }}</label>
                    <flat-pickr
                      v-model="appData.travelingdate"
                      :config="{
                        enableTime: false,
                        dateFormat: 'd-m-Y',
                        minDate: today,
                      }"
                      locale="en-In"
                      placeholder="DD-MM-YYYY"
                      class="form-control"
                    />
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <label> {{ $t("fields.modeoftravel") }}</label>
                    <inputselect
                      name="modeoftravel"
                      :tooltip="
                        $t('fields.select') + ' ' + $t('fields.modeoftravel')
                      "
                      :placeholder="
                        $t('fields.select') + ' ' + $t('fields.modeoftravel')
                      "
                      :valuex="appData.modeoftravel"
                      keys="key"
                      @updatedata="(val) => (appData.modeoftravel = val)"
                      :options="['Bus','Flight','Train','On-Own','Self-Drive Rental']"
                      id="modeoftravel"
                      validations="required"
                    ></inputselect>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    v-if="['Flight'].includes(appData.modeoftravel)"
                    class="mt-1"
                  >
                    <inputtext
                      name="flightname"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.flightname')
                      "
                      :label="$t('fields.flightname')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.flightname')
                      "
                      :valuex="appData.flightname"
                      @updatedata="(val) => (appData.flightname = val)"
                      validations=""
                    ></inputtext>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    v-if="['Flight'].includes(appData.modeoftravel)"
                    class="mt-1"
                  >
                    <inputtext
                      name="flightnum"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.flightnum')
                      "
                      :label="$t('fields.flightnum')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.flightnum')
                      "
                      :valuex="appData.flightnum"
                      @updatedata="(val) => (appData.flightnum = val)"
                      validations=""
                    ></inputtext>
                  </b-col>
                  <!-- <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="['Flight'].includes(appData.modeoftravel)"
                  >
                    <label> {{ $t("fields.flighttime") }}</label>
                    <flat-pickr
                      v-model="appData.flighttime"
                      :config="{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                      }"
                      placeholder="H-M"
                      class="form-control"
                    />
                  </b-col> -->

                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="['Train'].includes(appData.modeoftravel)"
                  >
                    <inputtext
                      name="trainnum"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.trainnum')
                      "
                      :label="$t('fields.trainnum')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.trainnum')
                      "
                      :valuex="appData.trainnum"
                      @updatedata="(val) => (appData.trainnum = val)"
                      validations=""
                    ></inputtext>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="['Train'].includes(appData.modeoftravel)"
                  >
                    <inputtext
                      name="trainname"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.trainname')
                      "
                      :label="$t('fields.trainname')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.trainname')
                      "
                      :valuex="appData.trainname"
                      @updatedata="(val) => (appData.trainname = val)"
                      validations=""
                    ></inputtext>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    v-if="['Flight','Train'].includes(appData.modeoftravel)"
                    class="mt-1"
                  >
                    <inputtext
                      name="pnr"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.pnr')
                      "
                      :label="$t('fields.pnr')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.pnr')
                      "
                      :valuex="appData.pnr"
                      @updatedata="(val) => (appData.pnr = val)"
                      validations=""
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1"
                  v-if="['Bus'].includes(appData.modeoftravel)"
                  >
                    <inputtext
                      name="bookingid"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.bookingid')
                      "
                      :label="$t('fields.bookingid')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.bookingid')
                      "
                      :valuex="appData.bookingid"
                      @updatedata="(val) => (appData.bookingid = val)"
                      validations=""
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1"
                  v-if="['Bus'].includes(appData.modeoftravel)"
                  >
                    <inputtext
                      name="travelsname"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.travelsname')
                      "
                      :label="$t('fields.travelsname')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.travelsname')
                      "
                      :valuex="appData.travelsname"
                      @updatedata="(val) => (appData.travelsname = val)"
                      validations=""
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1"
                  v-if="['Bus','Flight','Train'].includes(appData.modeoftravel)"
                  >
                    <inputtext
                      name="numpassengers"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.numpassengers')
                      "
                      :label="$t('fields.numpassengers')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.numpassengers')
                      "
                      :valuex="appData.numpassengers"
                      @updatedata="(val) => (appData.numpassengers = val)"
                      validations=""
                    ></inputtext>
                  </b-col>

                  <b-col sm="12" md="6" class="mt-1"
                  v-if="['Bus','Flight','Train'].includes(appData.modeoftravel)"
                  >
                    <inputtext
                      name="totalfars"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.totalfars')
                      "
                      :label="$t('fields.totalfars')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.totalfars')
                      "
                      :valuex="appData.totalfars"
                      @updatedata="(val) => (appData.totalfars = val)"
                      validations=""
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1"
                  v-if="['Self-Drive Rental'].includes(appData.modeoftravel)"
                  >
                    <inputtext
                      name="deposite"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.deposite')
                      "
                      :label="$t('fields.deposite')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.deposite')
                      "
                      :valuex="appData.deposite"
                      @updatedata="(val) => (appData.deposite = val)"
                      validations=""
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1"
                  v-if="['Self-Drive Rental'].includes(appData.modeoftravel)"
                  >
                    <inputtext
                      name="carrenddel"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.carrenddel')
                      "
                      :label="$t('fields.carrenddel')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.carrenddel')
                      "
                      :valuex="appData.carrenddel"
                      @updatedata="(val) => (appData.carrenddel = val)"
                      validations=""
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1"
                  v-if="['Self-Drive Rental'].includes(appData.modeoftravel)"
                  >
                    <label> {{ $t("fields.make") }}</label>
                    <inputselect
                      name="make"
                      :tooltip="$t('fields.select') + ' ' + $t('fields.make')"
                      :placeholder="
                        $t('fields.select') + ' ' + $t('fields.make')
                      "
                      :valuex="appData.makedeatails"
                      keys="key"
                      @updatedata="(val) => (appData.makedeatails = val)"
                      :options="makedeatails"
                      id="makedeatails"
                      validations=""
                    ></inputselect>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1"
                  v-if="['Self-Drive Rental'].includes(appData.modeoftravel)"
                  >
                    <inputtext
                      name="vehiclenumber"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.vehiclenumber')
                      "
                      :label="$t('fields.vehiclenumber')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.vehiclenumber')
                      "
                      :valuex="appData.vehiclenumber"
                      @updatedata="(val) => (appData.vehiclenumber = val)"
                      validations=""
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1"
                  v-if="['Self-Drive Rental'].includes(appData.modeoftravel)"
                  >
                    <inputtext
                      name="model"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.model')"
                      :label="$t('fields.model')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.model')
                      "
                      :valuex="appData.model"
                      @updatedata="(val) => (appData.model = val)"
                      validations="required"
                    >
                    </inputtext>
                  </b-col>

                  <b-col sm="12" md="6" class="mt-1"
                  v-if="['On-Own'].includes(appData.modeoftravel)"
                  >
                    <inputtext
                      name="fuelcost"
                      :tooltip="$t('fields.fuelcost') + ' ' + $t('fields.fuelcost')"
                      :label="$t('fields.fuelcost')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.fuelcost')
                      "
                      :valuex="appData.fuelcost"
                      @updatedata="
                        (val) => managefloat('fuelcost', parseFloat(val))
                      "
                      validations="integer"
                    >
                    </inputtext>
                  </b-col>



                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="remarks"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.remarks')"
                      :label="$t('fields.remarks')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.remarks')
                      "
                      :valuex="appData.remarks"
                      @updatedata="(val) => (appData.remarks = val)"
                      validations=""
                    ></inputtext>
                  </b-col>
                  <b-col cols="12">
                    <hr />
                    <b-form-checkbox
                      checked="true"
                      class="mt-2"
                      name="check-button"
                      switch
                      v-model="appData.hotell"
                      inline
                    >
                      <h5>{{ $t("fields.hotel") }}</h5>
                    </b-form-checkbox>
                    <b-row>
                      <b-col sm="12" md="6" calss="mt-2" v-if="appData.hotell">
                        <label> {{ $t("fields.fromdate") }}</label>
                        <flat-pickr
                          v-model="appData.fromdate"
                          :config="{ dateFormat: 'd-m-Y' }"
                          placeholder="DD-MM-YYYY"
                          class="form-control"
                        />
                      </b-col>
                      <b-col sm="12" md="6" calss="mt-2" v-if="appData.hotell">
                        <label> {{ $t("fields.todate") }}</label>
                        <flat-pickr
                          v-model="appData.todate"
                          :config="{ dateFormat: 'd-m-Y' }"
                          placeholder="DD-MM-YYYY"
                          class="form-control"
                        />
                      </b-col>
                      <b-col sm="12" md="6" class="mt-1"
                      v-if="appData.hotell"
                  >
                    <inputtext
                      name="hotelname"
                      :tooltip="$t('fields.hotelname') + ' ' + $t('fields.hotelname')"
                      :label="$t('fields.hotelname')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.hotelname')
                      "
                      :valuex="appData.hotelname"
                      @updatedata="(val) => (appData.hotelname = val)"
                      validations=""
                    >
                    </inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1"
                  v-if="appData.hotell"
                  >
                    <inputtext
                      name="totalfars"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.totalfars')
                      "
                      :label="$t('fields.totalfars')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.totalfars')
                      "
                      :valuex="appData.hoteltotalfars"
                      @updatedata="(val) => (appData.hoteltotalfars = val)"
                      validations=""
                    ></inputtext>
                  </b-col>
                    </b-row>
                  </b-col>
                  <b-col sm="12" v-if="edittype == 0">
                    <h5 class="mt-2">{{ $t("fields.fileupload") }}</h5>
                    <b-row>
                      <b-col sm="12" md="6" class="mt-2" lg="6">
                        <validation-provider
                          #default="validationContext"
                          name="Upload File"
                          rules=""
                        >
                          <b-form-group
                             :label="$t('fields.uploadfile')"
                            label-for="fileinput"
                          >
                            <b-form-file
                              accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf"
                              id="fileinput"
                              name="Upload File"
                              :placeholder="$t('fields.uploadfile')"
                              drop-placeholder="Drop file here..."
                              @change="updateValue($event)"
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col sm="12" class="mt-2">
                    <div class="d-flex float-right">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        class="mr-1"
                        variant="secondary"
                        @click="$router.go(-1)"
                      >
                        <feather-icon icon="ArrowLeftIcon" class="mr-25" />
                        <span class="align-middle"
                          >{{ $t("fields.back") }}
                        </span>
                      </b-button>
                      <b-button
                        v-if="edittype == 1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-1"
                        :to="{
                          name: GENAPP_APP_STORE_MODULE_NAME + '-view',
                          params: { id: iddata },
                        }"
                      >
                        <feather-icon icon="EyeIcon" class="mr-25" />
                        {{ $t("fields.view") }}
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary                            "
                        class="mr-1"
                        type="submit"
                      >
                        <feather-icon icon="Edit3Icon" class="mr-25" />
                        {{ $t("fields.save") }}
                      </b-button>
                    </div></b-col
                  >
                </b-row>
              </b-form>
              <!--/ form -->
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormDatepicker,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormFile,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import inputvselect from "@/views/Component/Input/inputvselect.vue";
import inputselect from "@/views/Component/Input/inputselect.vue";
import inputtext from "@/views/Component/Input/inputtext.vue";
import inputtextarea from "@/views/Component/Input/inputtextarea.vue";
import { required, alphaNum } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import inputcleave from "@/views/Component/Input/inputcleave.vue";
import store from "@/store";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import StoreModule from "./StoreModule";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
export default {
  components: {
    BCol,
    BCard,
    BRow,
    BForm,
    flatPickr,
    BCardBody,
    BFormGroup,
    inputcleave,
    inputselect,
    inputvselect,
    inputtextarea,
    inputtext,
    BFormCheckbox,
    BFormInput,
    BFormFile,
    BFormInvalidFeedback,
    BButton,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
    };
  },
  setup(props, { emit }) {
    const GENAPP_APP_STORE_MODULE_NAME = "leisure-travel";
    const modulename = "Travel";
    const modulenamesub = "travel";
    // Register module
    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    const toast = useToast();
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });
    let today = new Date();
    let key = 0;
    let edittype = 0;
    let storetype = "addData";
    let iddata = router.currentRoute.params.id;
    if (iddata != undefined) {
      edittype = 1;
      storetype = "editData";
    }
    const blankAppData = {
      name: "",
      travelingdate: "",
      departing: "",
      destination: "",
      modeoftravel: "",
      numpassengers: "",
      fromdate: "",
      trainnum: "",
      totalfars: "",
      hotell: 0,
      todate: "",
      flightname:"",
      trainname:"",
      fuelcost:0,
      makedeatails:"",
      flightnum: "",
      travelsname:"",
      bookingid:"",
      deposite:"",
      hoteltotalfars:"",
      pnr:"",
      model:"",
      vehiclenumber:"",
      hotelname:"",
      carrenddel:"",
      remarks: "",
      fileData: {
        name: "",
        file: "",
        size: "",
        type: "",
      },
    };
    const appData = ref(JSON.parse(JSON.stringify(blankAppData)));
    var tempcatch = 1;

    const onSubmit = () => {
      let temp = appData.value;
      let formData = new FormData();

      let appdatax = {
        customvalue: temp.customcategory != "Others" ? 0 : 1,
        travelingdate: temp.travelingdate,
        name: temp.modeoftravel,
        modeoftravel: temp.modeoftravel,
        departing: temp.departing,
        totalfars: temp.totalfars,
        trainnum: temp.trainnum,
        flightname: temp.flightname,
        hotell: temp.hotell,
        fromdate: temp.fromdate,
        bookingid: temp.bookingid,
        pnr: temp.pnr,
        makedeatails: temp.makedeatails,
        model: temp.model,
        vehiclenumber: temp.vehiclenumber,
        carrenddel: temp.carrenddel,
        hotelname: temp.hotelname,
        hoteltotalfars: temp.hoteltotalfars,
        fuelcost: temp.fuelcost,
        deposite: temp.deposite,
        travelsname: temp.travelsname,
        trainname: temp.trainname,
        flightnum: temp.flightnum,
        numpassengers: temp.numpassengers,
        todate: temp.todate,
        destination: temp.destination,
        remarks: temp.remarks,
      };
      let metadataappdatax = {
        customvalue: temp.customcategory != "Others" ? 0 : 1,
        numpassengers: temp.numpassengers,
        travelingdate: temp.travelingdate? temp.travelingdate.split('-').reverse().join('-') : null,
        name: temp.modeoftravel,

      };

      if (edittype == 1) {
        var jx = {
          id: router.currentRoute.params.id || null,
          customvalue: metadataappdatax.customvalue,
          data: appdatax,
          name: temp.modeoftravel,
          metadata: metadataappdatax,
        };
      } else {
        formData.append("name", temp.modeoftravel);
        formData.append("customvalue", metadataappdatax.customvalue);
        formData.append("data", JSON.stringify(appdatax));
        formData.append("metadata", JSON.stringify(metadataappdatax));

        if (temp.fileData.file) {
          let file = temp.fileData.file;
          formData.append("file", file);
        }
      }
      if (tempcatch) {
        tempcatch = 0;
        store
          .dispatch(
            GENAPP_APP_STORE_MODULE_NAME + "/" + storetype,
            edittype == 0 ? formData : jx
          )
          .then((response) => {
            if (response.data.success) {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  text:
                    modulename +
                    (edittype == 1 ? " editted" : " added") +
                    " successfully",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              router
                .push({
                  name: GENAPP_APP_STORE_MODULE_NAME,
                  params: {
                    id: response.data.id,
                  },
                })
                .catch(() => {});
            } else if (response.data.success == 0) {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  variant: "error",
                },
              });
              router
                .push({
                  name: GENAPP_APP_STORE_MODULE_NAME,
                  params: {
                    id: response.data.id,
                  },
                })
                .catch(() => {});
            }
          })
          .catch((error) => {
            tempcatch = 1;

            if (error.response.status === 404) {
              appData.value = undefined;
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  text: "Couldnt find " + modulenamesub + " details",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          });
      }
    };
    const makedeatails = ref([]);
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetcheautomobile")
      .then((response) => {
        makedeatails.value = response.data.data;

      });
    const familymembers = ref([]);
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchefamilymemberdetails")
      .then((response) => {
        familymembers.value = response.data.data;
      });

    // const state = ref([]);
    // store
    //   .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchestate")
    //   .then((response) => {
    //     state.value = response.data.data;
    //   });
    if (iddata != undefined) {
      store
        .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchData", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          if (!response.data.data) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Couldnt find " + modulenamesub + " details",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            router
              .push({
                name: GENAPP_APP_STORE_MODULE_NAME,
              })
              .catch(() => {});
          }

          appData.value = response.data.data.data;
          appData.value.name = response.data.data.name;
          key = Math.random();
        })
        .catch((error) => {
          if (error.response.status === 404) {
            appData.value = undefined;
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Couldnt find " + modulenamesub + " details",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else if (error.response.status === 500) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Input fields missing values",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else if (error.response.status === 422) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Please refresh your page",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    }
    const resetappData = () => {
      appData.value = JSON.parse(JSON.stringify(blankAppData));
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetappData);
    return {
      GENAPP_APP_STORE_MODULE_NAME,
      edittype,
      iddata,
      appData,
      modulename,
      modulenamesub,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      key,
      familymembers,
      today,
      makedeatails,
      tempcatch,

      // state
    };
  },
  mounted() {},
  methods: {
    // updatedetails(tit, val) {
    //
    //   if (tit == "holdernameid") {
    //     this.appData.holdernameid = val.id;
    //     this.appData.holdername = val.name;
    //   }
    // },
    managefloat(name, val) {
      this.$set(this.appData, name, parseFloat(val));
    },
    updateValue(value) {
      if (value.target.files[0].size <= 2 * 1048576) {
        this.appData.fileData.file = value.target.files[0];
        this.appData.fileData.name = value.target.files[0].name;
        this.appData.fileData.size = value.target.files[0].size;
        this.appData.fileData.type = value.target.files[0].type;
      } else {
        this.$swal({
          icon: "error",
          title: "File value Reached!",
          text: "File size must be maximum 2 mb .",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
